import {TOKEN_LOCAL_STORAGE} from "@/common/http";
import {ElMessageBox} from "element-plus";
import {i18n} from "@/common/localization";

export const PERMISSION_FINANCE_ACCESS = 'PERMISSION_FINANCE_ACCESS';
export const PERMISSION_ORDER_ACCESS = 'PERMISSION_ORDER_ACCESS';

export const logout = () => {
    ElMessageBox.confirm(
        i18n.global.t('logoutQuestion'),
        i18n.global.t('confirmTitle'),
        {
            confirmButtonText:  i18n.global.t('yes'),
            cancelButtonText:  i18n.global.t('no'),
            type: 'warning',
        }
    ).then(() => {
        localStorage.removeItem(TOKEN_LOCAL_STORAGE);
        window.location.href = "public";
    })
}