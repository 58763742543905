import {http} from "@/common/http";
import {AxiosResponse} from "axios/index";
import {AddToCart, Cart, CartRequest, ChangeCartQty} from "@/app/cart/Cart";
import {UploadRawFile} from "element-plus/es/components/upload/src/upload";

export const loadCart = async (
    request: CartRequest
): Promise<Cart> => {
    const {data} = await http.post<CartRequest,
        AxiosResponse<Cart>>('/cart', request);

    return Promise.resolve(data);
};

export const toCart = async (
    cartId: string,
    payload: AddToCart
): Promise<Cart> => {
    const {data} = await http.post<AddToCart,
        AxiosResponse<Cart>>(`/cart/${cartId}`, payload);

    return Promise.resolve(data);
};


export const deleteFromCart = async (
    cartId: string,
    productId: string
): Promise<Cart> => {
    const {data} = await http.delete<CartRequest,
        AxiosResponse<Cart>>(`/cart/${cartId}/products/${productId}`);

    return Promise.resolve(data);
};

export const changeCartProductQty = async (
    cartId: string,
    productId: string,
    qty: number
): Promise<Cart> => {
    const {data} = await http.put<ChangeCartQty,
        AxiosResponse<Cart>>(`/cart/${cartId}/products/${productId}`, {
        qty: qty
    });

    return Promise.resolve(data);
};

export const fillByOrder = async (
    cartId: string,
    orderId: string,
): Promise<Cart> => {
    const {data} = await http.put<null, AxiosResponse<Cart>>(`/cart/${cartId}/fill_by_order/${orderId}`);

    return Promise.resolve(data);
};

export const uploadCart = async (cartId: string, file: UploadRawFile, fileName: string): Promise<any> => {
    const {data} = await http.post<
        UploadRawFile,
        AxiosResponse<any>>(
        `/cart/${cartId}/upload`,
        file,
        {
            headers: {
                'Content-Type': 'application/octet-stream; charset=UTF-8',
                'Filename': unescape(encodeURIComponent(fileName))
            }
        });
    return Promise.resolve(data);
};