import {createI18n} from "vue-i18n";
import enUS from "@/locales/en-US.json";
import ruRU from "@/locales/ru-RU.json";
import {http} from "@/common/http";
import {AxiosResponse} from "axios";

type MessageSchema = typeof enUS

export const LOCALES = [
    {id: 'ru-RU', name: 'RU', value: 'ru'},
    {id: 'en-US', name: 'EN', value: 'en'}
];

export const CURRENT_LOCALE_LOCAL_STORAGE = 'locale';

export const getLocaleValue = (locale: string) => {
    return LOCALES.find(item => item.id === locale)?.value ?? 'ru';
}

const getLocaleId = (locale: string | null | undefined) => {
    if(!locale){
        return 'ru-RU';
    }
    return LOCALES.find(item => item.value === locale)?.id ?? 'ru-RU';
}

export const i18n = createI18n<[MessageSchema], 'en-US' | 'ru-RU'>({
    legacy: false,
    locale: getLocaleId(localStorage.getItem(CURRENT_LOCALE_LOCAL_STORAGE)),
    fallbackLocale: 'ru-RU',
    messages: {
        'en-US': enUS,
        'ru-RU': ruRU
    }
});

export interface TranslateField {
    id: string,
    objectType: string,
    objectId: string,
    objectField: string,
    locale: string,
    value: string
}

export interface TranslateFilter {
    objectType: string;
    objectId: string,
    objectField: string,
}

export const getTranslations = async (filter: TranslateFilter): Promise<Array<TranslateField>> => {
    const {data} = await http.post<
        TranslateFilter,
        AxiosResponse<Array<TranslateField>>>(`/admin/translations`, filter);

    return Promise.resolve(data);
};

export const saveTranslations = async (translate: TranslateField): Promise<Array<TranslateField>> => {
    const {data} = await http.put<
        TranslateField,
        AxiosResponse<Array<TranslateField>>>(`/admin/translations/` + translate.id, translate);

    return Promise.resolve(data);
};