import {computed} from "vue";
import ruRu from "element-plus/dist/locale/ru.mjs";
import enEn from "element-plus/dist/locale/en.mjs";
import {getLocaleValue, i18n} from "@/common/localization";
import "dayjs/locale/ru";
import "dayjs/locale/en.js";
import {dayjs} from "element-plus";

export const elementPlusLocale = computed(() => i18n.global.locale.value === 'ru-RU' ? ruRu : enEn);

export const changeDaysJsLocale = (value) => {
    dayjs.locale(getLocaleValue(value));
}