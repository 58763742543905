import {createApp} from 'vue'
import App from './App.vue'
import router from './router'
import {i18n} from "@/common/localization";
import '../index.scss'
import InlineSvg from 'vue-inline-svg';
import {store, key} from "@/app/store";

createApp(App)
    .use(router)
    .use(i18n)
    .use(store, key)
    .component("inline-svg", InlineSvg)
    .mount('#app');
