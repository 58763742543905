import axios, {AxiosInstance, AxiosRequestConfig, AxiosResponse, InternalAxiosRequestConfig} from "axios";
import {showErrorMessage} from "@/common/notifications";
import {CURRENT_LOCALE_LOCAL_STORAGE} from "@/common/localization";

export const TOKEN_LOCAL_STORAGE = 'user-token';

enum StatusCode {
    BadRequest = 400,
    Unauthorized = 401,
    Forbidden = 403,
    NotFound = 404,
    TooManyRequests = 429,
    InternalServerError = 500,
}

export const headers: Readonly<Record<string, string | boolean>> = {
    Accept: "application/json",
    "Content-Type": "application/json; charset=utf-8",
};

const injectToken = (config: InternalAxiosRequestConfig): InternalAxiosRequestConfig => {
    try {
        const token = localStorage.getItem(TOKEN_LOCAL_STORAGE);

        if (token != null && config.headers) {
            config.headers.Authorization = `Bearer ${token}`;
        }
        return config;
    } catch (error: any) {
        throw new Error(error);
    }
};

export const injectLocale = (config: InternalAxiosRequestConfig): InternalAxiosRequestConfig => {
    try {
        config.headers['Portal-Locale'] = localStorage.getItem(CURRENT_LOCALE_LOCAL_STORAGE) ?? "ru";
        return config;
    } catch (error: any) {
        throw new Error(error);
    }
};

class Http {
    private instance: AxiosInstance | null = null;

    private get http(): AxiosInstance {
        return this.instance != null ? this.instance : this.initHttp();
    }

    initHttp() {
        const http = axios.create({
            baseURL: process.env.VUE_APP_API_BASE_URL,
            headers,
            withCredentials: false,
        });

        http.interceptors.request.use(injectToken, (error) =>
            Promise.reject(error)
        );

        http.interceptors.request.use(injectLocale, (error) =>
            Promise.reject(error)
        );

        http.interceptors.response.use(
            (response) => response,
            (error) => {
                const {response} = error;
                return this.handleError(response);
            }
        );

        this.instance = http;
        return http;
    }

    request<T = any, R = AxiosResponse<T>>(
        config: AxiosRequestConfig
    ): Promise<R> {
        return this.http.request(config);
    }

    get<T = any, R = AxiosResponse<T>>(
        url: string,
        config?: AxiosRequestConfig
    ): Promise<R> {
        return this.http.get<T, R>(url, config);
    }

    post<T = any, R = AxiosResponse<T>>(
        url: string,
        data?: T,
        config?: AxiosRequestConfig
    ): Promise<R> {
        return this.http.post<T, R>(url, data, config);
    }

    put<T = any, R = AxiosResponse<T>>(
        url: string,
        data?: T,
        config?: AxiosRequestConfig
    ): Promise<R> {
        return this.http.put<T, R>(url, data, config);
    }

    delete<T = any, R = AxiosResponse<T>>(
        url: string,
        config?: AxiosRequestConfig
    ): Promise<R> {
        return this.http.delete<T, R>(url, config);
    }

    private handleError(error: any) {
        const {status, data} = error;
        switch (status) {
            case StatusCode.InternalServerError: {
                // Handle InternalServerError
                break;
            }
            case StatusCode.Forbidden: {
                showErrorMessage(data.message);
                break;
            }
            case StatusCode.BadRequest: {
                showErrorMessage(data.message);
                break;
            }
            case StatusCode.Unauthorized: {
                showErrorMessage(data.message);
                window.location.href = "/public/login";
                break;
            }
            case StatusCode.TooManyRequests: {
                // Handle TooManyRequests
                break;
            }
            case StatusCode.NotFound: {
                break;
            }
        }

        return Promise.reject(error);
    }
}

export const http = new Http();

export type ApiResponse<T> = {
    data: T[];
    limit: number;
    offset: number;
    sortableFields: any[];
    total: number;
};
